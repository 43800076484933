import { FaLinkedin, FaGithubSquare } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";

import './landingPage.css';

// import CV from '../../../../assets/cv/AkhilChandran-CV.pdf';
import developerImg from '../../../../assets/images/developer.png';
import designerImg from '../../../../assets/images/designer.png';



const LandingPage = () => {
    const handleScroll = () => {
        window.scroll({
            top: document.body.offsetHeight,
            left: 0,
            behavior: 'smooth',
        });
    }
    return (
        <div id="home" className='landing-page' style={{ height: window.innerHeight }}>
            <div className="landing-page-wrapper">
                <div className="landing-page-content">
                    <span className="landing-page-bg landing-page-animated">Hi There,</span>
                    <h1>I'm <br /> Akhil Chandran,
                    </h1>
                    <p>A Full-stack developer and Graphic Designer.</p>

                    <ul className='social-media-icons'>
                        <li className='linkedin-icon'><a href='https://www.linkedin.com/in/akhil-chandran/' target='__blank'><FaLinkedin /></a></li>
                        <li className='github-icon'><a href='https://github.com/akhilchandran1' target='__blank'><FaGithubSquare /></a></li>
                    </ul>
                    <div className="landing-page-button">
                        {/* <a className="landing-page-cart-btn" href={CV} download="Akhil Chandran CV.pdf"><FaFileDownload /> Download CV</a> */}
                        <button className="landing-page-cart-btn" onClick={handleScroll}>
                            <div><HiOutlineMail /> </div>
                            <div>Contact Me</div>
                        </button>
                    </div>

                </div>
                <div className="landing-page-imgs">
                    <img src={designerImg} className="landing-page-image" alt="" />
                    <img src={developerImg} className="landing-page-image" alt="" />

                </div>
            </div>

        </div>
    );
}

export default LandingPage;