const services = [
    {
        "name": "Website Development",
        "image": require("../../../assets/icons/backend.png"),
        "details": "Do you need help with Website Development?",

    },
    {
        "name": "Website Design",
        "image": require("../../../assets/icons/coding.png"),
        "details": "Do you need help with Website Design?"
    },
    {
        "name": "UI Design",
        "image": require("../../../assets/icons/ui.png"),
        "details": "Do you need help with UI Design?"
    },
    {
        "name": "UX Design",
        "image": require("../../../assets/icons/ux.png"),
        "details": "Do you need help with UX Design?"
    },
    {
        "name": "Graphic Design",
        "image": require("../../../assets/icons/designing.png"),
        "details": "Do you need help with Graphic Design?"
    },
    {
        "name": "Business Consulting",
        "image": require("../../../assets/icons/consulting.png"),
        "details": "Do you need help to boost your business?"
    }
]

export default services;