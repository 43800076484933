// import { useState } from "react";

// import { FaLaptopCode, FaDatabase, FaRobot, FaPaintBrush, FaCode, FaCalculator, FaAngleDown } from "react-icons/fa";
// import * as Icons from "react-icons/fa";

import './skills.css';

import skills from '../../data/skills.json';

const Skills = () => {
    return (

        <div id="skills" className="skills">

            <header><h1>Skills</h1></header>
            {
                skills.map((skill) => {
                    return (
                        <>
                            <span className="tag main-tag"> {skill.skillTitle}</span>
                            {(skill.skills).map((skl) => {
                                return <span className="tag sub-tag">{skl}</span>
                            })}
                        </>
                    );
                })
            }

        </div>
    );
};

export default Skills;