import './footer.css';
import { FaLinkedin, FaGithubSquare } from "react-icons/fa";

import ContactForm from './contactForm/ContactForm';

import servicesData from '../../data/services';

const Footer = () => {
    return (
        <>
            <div id='contact' className='footer-container'>
                <div className='footer-container-page-wrapper'>
                    <div>
                        <h3>Services</h3>
                        <ul className='footer-services-list'>
                            {servicesData.map((service) => {
                                return <li>{service.name}</li>
                            })}
                        </ul>
                        <div className='footer-socialmedia-icons'>
                            <a href='https://www.linkedin.com/in/akhil-chandran/' target='__blank' className='footer-linkedin-icon'><FaLinkedin /></a>
                            <a href='https://github.com/akhilchandran1' target='__blank' className='footer-github-icon'><FaGithubSquare /></a>
                        </div>
                    </div>
                    <div>
                        <h2>Contact Me</h2>
                        <ContactForm />
                    </div>
                </div>
            </div>
            <div className='footer-bottum-line'>
                <p>© {new Date().getFullYear()} Created by Akhil Chandran</p>
            </div>
        </>
    );
}

export default Footer;