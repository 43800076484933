import React from "react";

// import './homePageComponents.css';

import Recomentations from "./recommendations/Recommendations";
import Footer from "./footer/Footer";
import Services from "./services/Services";
import LandingPage from "./landingPage/LandingPage";
import Timeline from "./timeline/Timeline";
import Skills from "./skills/Skills";

const LandingPageCard = () => {
    return <LandingPage />
}

const RecomentationsPage = () => {
    return <Recomentations />
}

const SkillsCard = () => {
    return <Skills />
}

const ServicesCard = () => {
    return <Services />
}

const TimelineCard = () => {
    return <Timeline />
}

const FooterCard = () => {
    return <Footer />
}


export {
    LandingPageCard,
    RecomentationsPage,
    SkillsCard,
    ServicesCard,
    TimelineCard,
    FooterCard

}