import './timeline.css';

import timelineData from '../../data/timelineData.json';

const Timeline = () => {
    return (
        <div id='timeline' className='timeline'>
            <h1 className='timeline-main-title'>Timeline</h1>
            {timelineData.length > 0 && (
                <div className="timeline-container">
                    {timelineData.map((data, idx) => (
                        <div className="timeline-item" key={idx}>
                            <div className="timeline-item-content">
                                <span className="timeline-item-tag" >
                                    {data.category.tag}
                                </span>
                                <time>{data.date}</time>
                                <p>{data.text}</p>
                                {data.link && (data.link.url ?
                                    <a
                                        href={data.link.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {data.link.text}
                                    </a> : null
                                )}
                                <span className="circle" />
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default Timeline;