import Navbar from '../../components/Navbar/Navbar';
import { RecomentationsPage, ServicesCard, SkillsCard, TimelineCard, FooterCard } from './components/HomePageComponents';
import { LandingPageCard } from './components/HomePageComponents';

import './homePage.css';

const HomePage = () => {
    return (
        <>
            <Navbar />
            <LandingPageCard />
            <ServicesCard />
            <RecomentationsPage />
            <SkillsCard />
            <TimelineCard />
            <FooterCard />
        </>
    );
}

export default HomePage;