import { FaLinkedin, FaGlobeEurope } from "react-icons/fa";

import './recommendations.css';

import recommendations from '../../data/recommendations.json';

const Recomentations = () => {
    const logoStyle = {
        color: "white",
        backgroundColor: "DodgerBlue",
        paddingRight: "0.3rem",
        paddingLeft: "0.3rem",
        fontFamily: "Arial",
        borderRadius: "5px"
    };


    return (
        <div className="recomentations" id="recommendations">
            <h1 className="recomentations-main-title underline">Linked<span style={logoStyle}>in</span> Recommendations</h1>

            <div className="recomentations-container">
                {recommendations.map((recommendation, index) => {
                    return (
                        <div className="recomentation" key={index}>
                            <div className="recomentation-info">
                                <img src={recommendation.profilePicture} alt="" />
                                <div className="recomentation-head-text">
                                    <h2 className="recomentation-name">{recommendation.name}</h2>
                                    <p>{recommendation.designation}</p>
                                    <p>{recommendation.relation}</p>
                                    <div className="recommendation-profile-icons">
                                        {recommendation.linkedInProfile ? <a href={recommendation.linkedInProfile} target="__blank" className="linkedin-icon"><FaLinkedin /></a> : null}
                                        {recommendation.webAddress ? <a href={recommendation.webAddress} target="__blank" className="web-icon"><FaGlobeEurope /></a> : null}
                                    </div>
                                </div>
                            </div>
                            <div>

                            </div>

                            <div className="recomentation-content-text">
                                <p>{recommendation.message}</p>
                            </div>
                        </div>
                    );
                })
                }
            </div>
        </div>
    );
}

export default Recomentations;