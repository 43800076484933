import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';

import './Navbar.css';

export default function Navbar() {
  const [toggleMenu, setToggleMenu] = useState(false);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);


  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  }

  useEffect(() => {

    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', changeWidth)

    return () => {
      window.removeEventListener('resize', changeWidth)
    }

  }, []);

  return (
    <div className='nav-header'>
      <a class="nav-logo" href="/"><h3>Akhil Chandran</h3></a>
      <nav>

        {(toggleMenu || screenWidth > 550) && (
          <ul className="nav-key-elements-list">
            {/* <li className="nav-key-elements-list-items">
              <NavLink className={({ isActive }) => isActive ? "active" : null} onClick={() => window.location.replace("/#home")}>Home</NavLink>
            </li>
            <li className="nav-key-elements-list-items">
              <NavLink className={({ isActive }) => isActive ? "active" : null} onClick={() => window.location.replace("/#recommendations")}>Recomentations</NavLink>
            </li>
            <li className="nav-key-elements-list-items">
              <NavLink className={({ isActive }) => isActive ? "active" : null} onClick={() => window.location.replace("/#timeline")}>Timeline</NavLink>
            </li> */}
            <li className="nav-key-elements-list-items">
              <NavLink className={({ isActive }) => isActive ? "active nav-contact-btn" : "nav-contact-btn"} onClick={() => window.location.replace("/#contact")}>Contact</NavLink>
            </li>
          </ul>

          //   <ul className="nav-key-elements-list">
          //     <li className="nav-key-elements-list-items">
          //       <NavLink className={active ? "active" : null} to="home" onClick={(e) => {
          //         e.preventDefault()
          //         window.location.replace("/#home")
          //         isActive("/#home")
          //       }}>Home</NavLink>
          //     </li>
          //     <li className="nav-key-elements-list-items">
          //       <NavLink className={active ? "active" : null} to="recommendations"
          //         onClick={(e) => {
          //           e.preventDefault()
          //           window.location.replace("/#recommendations")
          //           isActive("/#recommendations")
          //         }}>Recommendations</NavLink>
          //     </li>
          //     <li className="nav-key-elements-list-items">
          //       <NavLink className={active ? "active" : null} to="timeline"
          //         onClick={(e) => {
          //           e.preventDefault()
          //           window.location.replace("/#timeline")
          //           isActive("/#timeline")
          //         }} >Timeline</NavLink>
          //     </li>
          //     <li className="nav-key-elements-list-items">
          //       <NavLink className={active ? "active nav-contact-btn" : "nav-contact-btn"} to="contact"
          //         onClick={(e) => {
          //           e.preventDefault()
          //           window.location.replace("/#contact")
          //           isActive("/#contact")
          //         }}>Contact</NavLink>
          //     </li>
          //   </ul>
        )}

        <button onClick={toggleNav} className="btn">{toggleMenu ? <RiCloseLine /> : <RiMenu3Line />}</button>
      </nav>

    </div>
  )
}
