import { Routes, Route } from 'react-router-dom';

import './App.css';
import HomePage from "./pages/home/HomePage";
import Layout from './components/Layout';
import Missing from './pages/notFoundPage/Missing';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route path='/' element={<HomePage />} />
        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  );
}

export default App;
