import React, { useRef, useState } from "react";
import emailjs from '@emailjs/browser';

import './contactForm.css';

const ContactForm = () => {
    const form = useRef();
    const [statusMessage, setStatusMessage] = useState();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_gwdgrnl', 'template_quh5dxl', form.current, 'w58ze3_Gs5bJe37i0')
            .then((result) => {
                // console.log(result.text);
                setStatusMessage("Message send");
                form.current.reset();
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <>
            <form ref={form} onSubmit={sendEmail} className="contact-form">
                <input type="text" name="user_name" placeholder="Your name" />
                <input type="email" name="user_email" placeholder="Email" />
                <input type="text" name="subject" placeholder="Subject" />
                <textarea name="message" placeholder="Your message" />
                <input type="submit" value="Send" />
            </form>
            {statusMessage ? <p className="success-message">{statusMessage}</p> : null}
        </>
    );
}

export default ContactForm;

