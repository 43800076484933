import './services.css';

import services from '../../data/services';

const Services = () => {

    return (
        <div id='services' class="key-services">
            <div class="key-services-container">
                <ul class="key-services-list">
                    {services.map((service, index) => {
                        return (
                            <li class="key-services-item" key={index}>
                                <div class="key-services-visible-item">
                                    <div class="key-services-img-box">
                                        <img src={service.image} class="key-services-img" alt="" />
                                    </div>
                                    <div class="key-services-title">
                                        {service.name}
                                    </div>
                                </div>
                                <div class="key-services-hidden-item">
                                    <p class="key-services-text">
                                        {service.details}
                                    </p>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
}

export default Services;